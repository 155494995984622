<template>
  <div style="width: 100%; height: 100%; overflow: auto">
    <div class="p-register">
      <section class="p-register-left">
        <div class="p-register-left_content">
          <h3>我们只服务认真的你</h3>
          <h3 style="font-size: 28px">注册即享元器件行业大数据！</h3>
          <p>• 库存管理，精确而不失灵活</p>
          <p>• 单据打印，多模板多公司</p>
          <p>• 处理询报价，效率提升200%</p>
          <p>• 快速市场找货，整合首选供应商</p>
          <p>• BOM批量配单，从未如此Easy</p>
          <p>• 多个子账号，多地办公，业务全面IT化</p>
          <p>• 更多功能，期待你来发现……</p>
        </div>
      </section>
      <section class="p-register-right">
        <div class="p-register-right_content">
          <div class="top">
            <p><span>返回首页</span></p>
          </div>
          <div class="log">
            <figure class="log_img">
              <a href="https://www.bom.ai">
                <img alt="" src="//oss.bom.ai/erp-static/img/erp-logo-v2.png" />
              </a>
            </figure>
            <i class="log_line"></i>
            <span class="log_title">注册账号</span>
            <p class="log_login">
              <span>已有账号？</span
              ><a href="javascript:void(0)" @click="onLogin">马上登录</a>
            </p>
          </div>
          <section class="box">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              class="demo-ruleForm ruleForm"
              label-position="left"
              label-width="80px"
              status-icon
            >
              <el-form-item label="登录账号" prop="account" required>
                <el-input
                  v-model.number="ruleForm.account"
                  placeholder="3~18位数字、字母任意组合"
                  type="text"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="pass" required>
                <el-input
                  v-model.number="ruleForm.pass"
                  placeholder="6-30位数字、字母任意组合"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item label="公司名称" prop="company" required>
                <el-input
                  v-model="ruleForm.company"
                  autocomplete="off"
                  placeholder="填写公司营业执照中的名称"
                  type="text"
                >
                </el-input>
                <span class="companyTips" style="color: #000">
                  <i class="el-icon-warning" style="margin-right: 3px"></i
                  >填写公司营业执照中的名称，后续更改，须提供营业执照副本</span
                >
              </el-form-item>
              <el-form-item
                label="公司地址"
                required
                prop="province"
                class="el-form-item_content-flex"
              >
                <el-row :gutter="0">
                  <el-col :span="8">
                    <el-select
                      v-model="ruleForm.province"
                      filterable
                      placeholder="省"
                      @change="provinceCityChange('province')"
                    >
                      <el-option
                        v-for="item in provinceOptions"
                        :key="item.code"
                        :label="item.label"
                        :value="item.code"
                      >
                      </el-option> </el-select
                  ></el-col>
                  <el-col :span="8">
                    <el-select
                      v-model="ruleForm.city"
                      filterable
                      placeholder="市"
                      @change="provinceCityChange('city')"
                    >
                      <el-option
                        v-for="item in cityOptions"
                        :key="item.code"
                        :label="item.label"
                        :value="item.code"
                      >
                      </el-option> </el-select
                  ></el-col>
                  <el-col :span="8">
                    <el-select
                      v-model="ruleForm.district"
                      filterable
                      placeholder="区"
                    >
                      <el-option
                        v-for="item in districtOptions"
                        :key="item.code"
                        :label="item.label"
                        :value="item.code"
                      >
                      </el-option> </el-select
                  ></el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="详细地址" prop="address" required>
                <el-input
                  v-model="ruleForm.address"
                  autocomplete="off"
                  placeholder="填写公司详细地址"
                  type="text"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系人" prop="contact" required>
                <el-input
                  v-model.number="ruleForm.contact"
                  placeholder="联系人"
                  type="text"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机号码" required>
                <el-row :gutter="5">
                  <el-col :span="10">
                    <el-form-item prop="phone">
                      <el-input
                        v-model.number="ruleForm.phone"
                        placeholder="手机号码"
                        type="text"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item>
                      <el-input
                        v-model.number="ruleForm.code"
                        placeholder="验证码"
                        type="text"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <znl-button
                      :disabled="codeDisabled"
                      :height="28"
                      :width="78"
                      style-type="minor"
                      @click="onGetCode"
                    >
                      {{ codeBtnText }}
                    </znl-button>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item label="信息来源" prop="fromType" required>
                <el-select
                  v-model="ruleForm.fromType"
                  placeholder="从哪儿知道正能量？"
                  style="width: 340px"
                >
                  <el-option label="业务上门" value="1"></el-option>
                  <el-option label="电话销售" value="2"></el-option>
                  <el-option label="朋友介绍" value="3"></el-option>
                  <el-option label="百度搜索" value="4"></el-option>
                  <!-- <el-option label="华强网广告" value="5"></el-option> -->
                  <el-option label="其他" value="6"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="推荐人">
                <el-input
                  v-model.number="ruleForm.referrer"
                  placeholder="揄入与您联系的业务员的名字"
                  type="text"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <znl-button
                  :height="40"
                  style="width: 100%"
                  style-type="main"
                  @click="submitForm('ruleForm')"
                  >立即注册
                </znl-button>
              </el-form-item>
            </el-form>
          </section>
          <section class="phone">注册咨询: 400-699-2899</section>
        </div>
        <div class="p-register-right_footer">
          <div class="info">
            <p>
              <span>深圳总部：</span
              >深圳市福田区华强北街道振兴路桑达工业区418栋曼哈数码广场A栋四楼双创中心东面
            </p>
            <p>
              <span>香港：</span>九龍觀塘成業街19-21號，成業工業大廈3樓33室
              ADDRESS: Unit 33, 3/F.,Shing Yip Industrial Building,No.19-21
              Shing Yip Street,Kwun Tong,Kowloon
            </p>
            <p><span>上海：</span>上海市静安区天目西路218嘉里不夜城b座1207室</p>
            <p><span>客服QQ：</span>800106568</p>
            <p><span> 客服电话：</span>400-699-2899</p>
          </div>
          <section style="margin-top: 10px">
            <a
              href="https://beian.miit.gov.cn/"
              style="color: rgb(153, 153, 153)"
            >
              ©{{ copyright }} 深圳市正能量网络技术有限公司|粤ICP备17005480号</a
            >
          </section>
        </div>
      </section>
    </div>

    <div>
      <el-dialog
        :visible.sync="dialogVisible"
        class="p-register_dialog"
        title="图文验证码"
        :close-on-click-modal="false"
        width="300px"
      >
        <div>
          <div class="p-register_dialog-captcha">
            <znl-input
              v-model="imgUrlData"
              :border="true"
              :height="40"
              :width="160"
              placeholder=""
              required="required"
              type="text"
            >
            </znl-input>
            <img
              :src="CaptchaUrl"
              alt=""
              class="mobile-img"
              @click="onReloadCaptcha"
            />
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="onCaptchaConfig">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "RegisterView",
  data() {
    let validateAccount = (rule, value, callback) => {
      const rules = /^[a-zA-Z0-9]{3,18}$/;
      if (value === "") {
        callback(new Error("请输入登录账号"));
      } else if (!rules.test(value)) {
        callback(new Error("账号为 3~18位数字、字母任意组合"));
      } else {
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      const rules = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,30}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!rules.test(value)) {
        callback(new Error("密码为 3~18位数字、字母任意组合"));
      } else {
        callback();
      }
    };
    let validatePhone = (rule, value, callback) => {
      const rules =
        /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!rules.test(value)) {
        callback(new Error("请输入正确的手机号!"));
      } else {
        callback();
      }
    };

    let validateProvinceCityDistrict = (rule, value, callback) => {
      let { province, city, district } = this.ruleForm;

      let provinceName = province
        ? this.ChinaCityDataSource["86"][province]
        : "";
      let cityName =
        province && city ? this.ChinaCityDataSource[province][city] : "";
      let districtName =
        city && district ? this.ChinaCityDataSource[city][district] : "";

      if (provinceName === "") {
        callback(new Error("请选择省"));
      } else if (cityName === "") {
        callback(new Error("请选择市"));
      } else if (
        (districtName === "" || districtName === "市辖区") &&
        provinceName !== "香港特别行政区" &&
        provinceName !== "澳门特别行政区"
      ) {
        callback(new Error("请选择区"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        account: "", // 账号
        pass: "", // 密码
        company: "", // 公司名称
        address: "", // 公司地址
        contact: "", // 联系人
        phone: "", // 手机号
        code: "", // 验证码

        fromType: "", // 信息来源
        referrer: "", // 推荐人

        province: "440000", // 省，默认选中广东省
        city: "440300", // 市，默认选中深圳市
        district: "440304", // 区，默认选中福田区
      },
      rules: {
        account: [{ validator: validateAccount, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        company: [
          {
            required: true,
            message: "请输入公司营业执照中的名称",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入公司详细地址", trigger: "blur" },
        ],
        contact: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        fromType: [
          { required: true, message: "请选择信息来源", trigger: "change" },
        ],
        province: [
          { validator: validateProvinceCityDistrict, trigger: "change" },
        ],
      },
      codeDisabled: false,
      codeBtnText: "获取验证码",
      dialogVisible: false,
      imgUrlData: null,
      CaptchaUrl: null,

      ChinaCityDataSource: {}, //中国城市数据源
    };
  },
  computed: {
    provinceOptions() {
      let obj = this.ChinaCityDataSource["86"];
      let list = [];
      for (let key in obj) {
        list.push({
          label: obj[key],
          code: key,
        });
      }
      return list;
    },
    cityOptions() {
      let { province } = this.ruleForm;
      if (province === "") return [];
      let obj = this.ChinaCityDataSource[province];
      let list = [];
      for (let key in obj) {
        list.push({
          label: obj[key],
          code: key,
        });
      }
      return list;
    },
    districtOptions() {
      let { city } = this.ruleForm;
      if (city === "") return [];
      let obj = this.ChinaCityDataSource[city];
      let list = [];
      for (let key in obj) {
        list.push({
          label: obj[key],
          code: key,
        });
      }
      return list;
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // "CompanyName": "陈祥鹏测试",
          //   "Account": "cxp123",
          //   "Password": "znl123456",
          //   "Mobile": "13713814045",
          //   "Email": "komson@qq.com",
          //   "CompanyAddress": "深圳市正能量华强北地区",
          //   "FromBy": "百度",
          //   "Telephone": "",
          //   "Referees": "陈祥鹏",
          //   "Captcha": "1234"

          const editMaps = {
            CompanyName: "company",
            Account: "account",
            Password: "pass",
            Mobile: "phone",
            Contact: "contact",
            CompanyAddress: "address",
            FromBy: "fromType",
            Telephone: "phone",
            Referees: "referrer",
            Captcha: "code",
            Province: "province",
            City: "city",
            District: "district",
          };
          const params = {};

          Object.entries(editMaps).forEach(([key, src]) => {
            params[key] = this.ruleForm[src];
          });

          params.Province = this.ruleForm.province
            ? this.ChinaCityDataSource["86"][this.ruleForm.province]
            : "";
          params.City =
            this.ruleForm.province && this.ruleForm.city
              ? this.ChinaCityDataSource[this.ruleForm.province][
                  this.ruleForm.city
                ]
              : "";
          params.District =
            this.ruleForm.city && this.ruleForm.district
              ? this.ChinaCityDataSource[this.ruleForm.city][
                  this.ruleForm.district
                ]
              : "";
          this.$post("Security/Register", params, (data, log) => {
            if (log.code === 200) {
              this.$message({
                message: "注册成功，即将跳转登录页面",
                type: "success",
              });
              this.$router.push("/login");
            } else {
              this.$message({
                message: log.msg,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    provinceCityChange(key) {
      switch (key) {
        case "province":
          this.ruleForm.city = "";
          this.ruleForm.district = "";
          break;
        case "city":
          this.ruleForm.district = "";
          break;
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    async onGetCode() {
      const { phone } = this.ruleForm;
      if (!phone) {
        this.$message({
          message: "请填写正确的手机号",
          type: "error",
        });
        return false;
      }
      // const param = {
      //   Mobile: phone,
      //   Type: 1
      // }
      //
      // this.$post('Captcha/SendRegisterCode', param, (code, data) => {
      //   if (data.code === 200) {
      //     this.codeDisabled = true
      //     const that = this
      //     let time = 120
      //     const TimeId = setInterval(() => {
      //       that.codeBtnText = `${time}秒后重发`;
      //       if (time <= 0) {
      //         clearInterval(TimeId);
      //         that.codeDisabled = false;
      //         that.codeBtnText = `发送验证码`;
      //       }
      //       time--
      //     }, 1000)
      //
      //     this.$message({
      //       message: '验证码已成功发送',
      //       type: 'success'
      //     })
      //   } else {
      //     this.$message({
      //       message: data.msg,
      //       type: 'error'
      //     })
      //   }
      // })

      this.dialogVisible = true;

      await this.onReloadCaptcha();
    },

    onLogin() {
      this.$router.push("/login");
    },

    // 图形验证码
    async onReloadCaptcha() {
      const ts = Date.now();
      this.CaptchaUrl = `/api/Captcha/GetImageCaptcha?t=${ts}`;
    },

    async onCaptchaConfig() {
      let imgUrlData = this.imgUrlData;
      let err = "";
      if (!imgUrlData) {
        err = "请先输入图文验证码";
      }
      if (err) {
        this.$message({
          message: err,
          type: "warning",
        });
        return false;
      }

      let params = {
        Mobile: this.ruleForm.phone,
        ImageCaptcha: this.imgUrlData,
        Type: 1,
      };
      try {
        this.$post("Captcha/SendRegisterCode", params, (code, data) => {
          if (data.code === 200) {
            this.dialogVisible = false;
            this.codeDisabled = true;
            const that = this;
            let time = 120;
            const TimeId = setInterval(() => {
              that.codeBtnText = `${time}秒后重发`;
              if (time <= 0) {
                clearInterval(TimeId);
                that.codeDisabled = false;
                that.codeBtnText = `发送验证码`;
              }
              time--;
            }, 1000);

            this.$message({
              message: "验证码已成功发送",
              type: "success",
            });
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
      } catch (e) {
        throw e;
      }
    },

    //获取城市资源
    async GetChinaCity() {
      this.$post("/Security/GetChinaCity", null, (data, logic) => {
        if (logic.code === 200) {
          this.ChinaCityDataSource = data;
        } else {
          this.$message({
            message: logic.msg,
            type: "error",
          });
        }
      });
    },
  },

  created() {
    this.GetChinaCity();
  },
};
</script>
<style lang="scss">
.ruleForm {
  width: 420px;

  .companyTips {
    font-size: 12px;
  }
}

.p-register {
  width: 100%;
  height: 100%;
  min-height: 840px;
  display: flex;
  background: #ffffff;
  overflow: auto;

  .p-register-left {
    width: 550px;
    height: 100%;
    background: url(~assets/images/reg-left.png) no-repeat 50%;
    background-size: 550px 100%;
    vertical-align: top;

    .p-register-left_content {
      height: 100%;
      width: 550px;
      background: rgba(0, 0, 0, 0.7);
      margin: 0 auto;
      padding-top: 35%;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        width: 450px;
      }

      p {
        width: 450px;
        font-size: 24px;
      }
    }
  }

  .p-register-right {
    flex: 1;
    position: relative;

    .p-register-right_content {
      box-sizing: border-box;
      padding-left: 10%;

      .top {
        height: 60px;
        width: 600px;
        line-height: 60px;
        font-size: 12px;
        color: #999;
        text-align: right;
      }

      .log {
        width: 520px;
        height: 30px;
        vertical-align: bottom;
        margin-bottom: 50px;
        position: relative;

        .log_img {
          padding: 0;
          margin: 0;
          display: inline-block;

          img {
            height: 50px;
          }
        }

        .log_line {
          width: 2px;
          height: 46px;
          background: #979797;
          margin-left: 10px;
          display: inline-block;
        }

        .log_title {
          margin-left: 5px;
          display: inline-block;
          font-size: 18px;
          color: #666;
          vertical-align: bottom;
        }

        .log_login {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 14px;

          span {
            color: #666;
          }

          a {
            color: rgb(34, 136, 204);
            text-decoration: underline;
          }
        }
      }

      .box {
        width: 355px;

        // 改变组件样式
        .el-form-item {
          margin-bottom: 0;
        }

        .el-form-item__error {
          top: 10px;
          transform: translate(345px, 0);
        }

        .el-input__inner {
          height: 30px;
        }
      }

      .phone {
        font-size: 24px;
        color: #666;
        margin-top: 30px;
        font-weight: bold;
        line-height: 35px;
      }

      .phone:before {
        content: "";
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url(~assets/images/phone_ic.png);
        background-size: cover;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }

  .p-register-right_footer {
    box-sizing: border-box;
    position: absolute;
    bottom: 3%;
    left: 10%;
    font-size: 12px;
    color: #999;

    p {
      margin-bottom: 3px;

      span {
        display: inline-block;
        width: 70px;
      }
    }
  }
}

.p-register_dialog {
  .el-dialog__footer {
    height: 10px 20px;
  }

  .el-dialog__body {
    padding: 20px 10px;
  }
}
.p-register_dialog-captcha {
  .znl-component-input {
    height: 35px !important;
  }
  .el-input__inner {
    height: 35px !important;
  }
  img {
    vertical-align: top;
    height: 35px;
    margin-left: 8px;
  }
}
</style>
